<template>
	<div class="row">
		<div :class="routes.length ? 'col-md-8' : 'col-md-12'">
			<div class="row mb10">
				<div class="col-md-3">
					<b>Tipo de traslado</b>
					<a-select :style="{ width: '100%' }" v-model="moveType">
						<a-select-option value="delivery"> Entrega desde sucursal </a-select-option>
						<a-select-option value="movement"> Traslado </a-select-option>
					</a-select>
				</div>
				<div class="col-md-3" v-show="moveType == 'movement'">
					<b>Punto de recolección</b>
					<div class="mb20">
						<a-input type="text" id="search_input_start" v-model="location.start.name" />
					</div>
				</div>
				<div class="col-md-3" v-show="moveType != ''">
					<b>Punto de entrega</b>
					<div class="mb20">
						<a-input type="text" id="search_input_end" v-model="location.end.name" />
					</div>
				</div>
				<div class="col-md-3" v-show="moveType != ''">
					<b>Retorno</b>
					<div class="mb20">
						<a-input type="text" id="search_input_return" v-model="location.end.return" />
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<div id="map" style="width: 100%; height: 500px"></div>
				</div>
			</div>
		</div>
		<div class="col-md-4" v-if="routes.length">
			<h4 class="text-muted">Tarifa de Traslado</h4>
			<hr class="hrText" data-content="♦" />
			<div v-show="moveType == 'movement'">
				<label><b>Punto de recolección</b>: </label>
				{{ location.start.name }}
			</div>
			<div>
				<label><b>Punto de entrega</b>: </label>
				{{ location.end.name }}
			</div>
			<div v-if="moveType == 'movement'">
				<label><b>Distancia inicial sin carga</b>: </label>
				{{ unloadedDistance }} Km - {{ numeral(calculateUnloadedDistance).format('$0,0.00') }}
			</div>
			<div>
				<label><b>Distancia con carga</b>: </label>
				{{ loadedDistance }} Km - {{ numeral(calculateLoadedDistance).format('$0,0.00') }}
			</div>
			<div>
				<label><b>Distancia de retorno</b>: </label>
				{{ returnDistance }} Km - {{ numeral(calculateReturnDistance).format('$0,0.00') }}
			</div>
			<div>
				<label><b>Tiempo aproximado de traslado</b>: </label>
				{{ routes[0].legs[1].duration.text }}
			</div>
			<hr />
			<div class="row">
				<div class="col-lg-6">
					<div>
						<b>Costo del Servicio</b>
						<h5>{{ numeral(getTotalUniqueCost * 1.15).format('$0,0.00') }}</h5>
					</div>
					<div>
						<b>Descuento "LOS COMPAS" del 10%</b>
						<h5>-{{ numeral(getTotalUniqueCost * 1.15 * 0.1).format('$0,0.00') }}</h5>
					</div>
					<div>
						<b>Total a Pagar</b>
						<h5>{{ numeral(getTotalUniqueCost * 1.15 * 0.9).format('$0,0.00') }}</h5>
					</div>
					<div class="text-center">
						<a-button class="btn btn-sm btn-info" @click="copyQuotation(1)">Copiar cotización</a-button>
					</div>
				</div>
				<div class="col-lg-6">
					<div>
						<b>Costo del Servicio</b>
						<h5>{{ numeral(getTotalUniqueCost * 1.15).format('$0,0.00') }}</h5>
					</div>
					<div>
						<b>Descuento "LOS COMPAS" del 10%</b>
						<h5>-{{ numeral(getTotalUniqueCost * 1.15 * 0.1).format('$0,0.00') }}</h5>
					</div>
					<div>
						<b>Descuento "Chingón" del 10%</b>
						<h5>-{{ numeral(getTotalUniqueCost * 1.15 * 0.1 * 0.9).format('$0,0.00') }}</h5>
					</div>
					<div>
						<b>Total a Pagar</b>
						<h5>{{ numeral(getTotalUniqueCost * 1.15 * 0.9 * 0.9).format('$0,0.00') }}</h5>
					</div>
					<div class="text-center">
						<a-button class="btn btn-sm btn-info" @click="copyQuotation(2)">Copiar cotización</a-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { Loader } from '@googlemaps/loader'
import numeral from 'numeral'
import utilities from '@/services/utilities'
import copy from 'copy-to-clipboard'

const loader = new Loader({
	apiKey: 'AIzaSyCov2muN18SzF5_QjKbjCnS9owgZo62CiI',
	version: 'weekly',
	libraries: ['places'],
})

const mapOptions = {
	zoom: 12,
	center: { lat: 19.4082176, lng: -99.0341906 },
}

const autocompleteOptions = {
	types: ['geocode', 'establishment'],
	componentRestrictions: { country: 'mx' },
	fields: ['address_components', 'geometry', 'icon', 'name'],
	strictBounds: false,
}

export default {
	data() {
		return {
			map: undefined,
			autocomplete: {
				initial: undefined,
				start: undefined,
				end: undefined,
				return: undefined,
			},
			nearPlace: {},
			routes: [],
			location: {
				initial: {
					name: '',
					coords: [mapOptions.center.lat, mapOptions.center.lng],
				},
				start: {
					name: '',
					coords: [],
				},
				end: {
					name: '',
					coords: [],
				},
				return: {
					name: '',
					coords: [],
				},
			},
			navigationLinks: {
				waze: '',
			},
			waypoints: [],
			base: 300,
			moveType: '',
		}
	},
	computed: {
		getDistance() {
			let total = 0
			if (utilities.objectValidate(this.routes, [0, 'legs'], false)) {
				this.routes[0].legs.forEach((element) => {
					total += numeral(element.distance.value).value()
				})
			}
			return numeral(total / 1000).value()
		},
		getTotalUniqueCost() {
			return this.base + this.calculateUnloadedDistance + this.calculateLoadedDistance + this.calculateReturnDistance
		},
		unloadedDistance() {
			return numeral(numeral(this.routes[0].legs[0].distance.value).value() / 1000).format('0.0')
		},
		calculateUnloadedDistance() {
			return this.moveType == 'delivery' ? 0 : numeral(this.unloadedDistance).value() * 10
		},
		loadedDistance() {
			return numeral(numeral(this.routes[0].legs[1].distance.value).value() / 1000).format('0.0')
		},
		calculateLoadedDistance() {
			let distance = numeral(this.loadedDistance).value()
			let factor = 0
			if (distance <= 5) {
				factor = 60
			}
			if (distance > 5 && distance <= 10) {
				factor = 55
			}
			if (distance > 10 && distance <= 15) {
				factor = 50
			}
			if (distance > 15 && distance <= 20) {
				factor = 45
			}
			if (distance > 20 && distance <= 25) {
				factor = 40
			}
			if (distance > 25 && distance <= 30) {
				factor = 35
			}
			if (distance > 30 && distance <= 35) {
				factor = 30
			}
			if (distance > 35 && distance <= 40) {
				factor = 25
			}
			if (distance > 40) {
				factor = 20
			}
			return numeral(distance).value() * factor
		},
		returnDistance() {
			return numeral(numeral(utilities.objectValidate(this.routes, [0, 'legs', this.moveType == 'delivery' ? 1 : 2, 'distance', 'value'], 0)).value() / 1000).format('0.0')
		},
		calculateReturnDistance() {
			return numeral(this.returnDistance).value() * 5
		},
	},
	mounted() {
		//
		let _this = this
		loader.loadCallback((e) => {
			if (e) {
				console.log(e)
			} else {
				_this.map = new google.maps.Map(document.getElementById('map'), mapOptions)

				// _this.autocomplete.initial = new google.maps.places.Autocomplete(document.getElementById('search_input_initial'), autocompleteOptions)
				_this.autocomplete.start = new google.maps.places.Autocomplete(document.getElementById('search_input_start'), autocompleteOptions)
				_this.autocomplete.end = new google.maps.places.Autocomplete(document.getElementById('search_input_end'), autocompleteOptions)
				_this.autocomplete.return = new google.maps.places.Autocomplete(document.getElementById('search_input_return'), autocompleteOptions)

				const directionsService = new google.maps.DirectionsService()
				const directionsDisplay = new google.maps.DirectionsRenderer()

				directionsDisplay.setMap(_this.map)

				// _this.autocomplete.initial.setComponentRestrictions({
				// 	country: ['mx'],
				// })
				_this.autocomplete.start.setComponentRestrictions({
					country: ['mx'],
				})
				_this.autocomplete.end.setComponentRestrictions({
					country: ['mx'],
				})
				_this.autocomplete.return.setComponentRestrictions({
					country: ['mx'],
				})

				// google.maps.event.addListener(_this.autocomplete.initial, 'place_changed', function () {
				// 	_this.nearPlace = _this.autocomplete.initial.getPlace()
				// 	_this.location.initial = {
				// 		name: _this.nearPlace.name,
				// 		coords: [numeral(_this.nearPlace.geometry.location.lat()).value(), numeral(_this.nearPlace.geometry.location.lng()).value()],
				// 	}
				// 	// _this.navigationLinks.waze = `https://www.waze.com/ul?ll=${_this.nearPlace.geometry.location.lat()}%2C${_this.nearPlace.geometry.location.lng()}&navigate=yes&zoom=17`
				// 	calculateAndDisplayRoute(directionsService, directionsDisplay)
				// })

				google.maps.event.addListener(_this.autocomplete.start, 'place_changed', function () {
					_this.nearPlace = _this.autocomplete.start.getPlace()
					_this.location.start = {
						name: _this.nearPlace.name,
						coords: [numeral(_this.nearPlace.geometry.location.lat()).value(), numeral(_this.nearPlace.geometry.location.lng()).value()],
					}
					calculateAndDisplayRoute(directionsService, directionsDisplay)
				})

				google.maps.event.addListener(_this.autocomplete.end, 'place_changed', function () {
					_this.nearPlace = _this.autocomplete.end.getPlace()
					_this.location.end = {
						name: _this.nearPlace.name,
						coords: [numeral(_this.nearPlace.geometry.location.lat()).value(), numeral(_this.nearPlace.geometry.location.lng()).value()],
					}
					calculateAndDisplayRoute(directionsService, directionsDisplay)
				})

				google.maps.event.addListener(_this.autocomplete.return, 'place_changed', function () {
					_this.nearPlace = _this.autocomplete.return.getPlace()
					_this.location.return = {
						name: _this.nearPlace.name,
						coords: [numeral(_this.nearPlace.geometry.location.lat()).value(), numeral(_this.nearPlace.geometry.location.lng()).value()],
					}
					calculateAndDisplayRoute(directionsService, directionsDisplay)
				})

				function makeRoute(payload) {
					directionsService.route(payload, function (response, status) {
						if (status === 'OK') {
							directionsDisplay.setDirections(response)
							_this.routes = response.routes
						}
					})
				}

				function calculateAndDisplayRoute() {
					let payload = {
						origin: { lat: _this.location.initial.coords[0], lng: _this.location.initial.coords[1] },
						travelMode: 'DRIVING',
						waypoints: [],
					}

					if (_this.moveType == 'movement') {
						if (
							utilities.objectValidate(_this.location, ['initial', 'coords', 0], false) &&
							utilities.objectValidate(_this.location, ['start', 'coords', 0], false) &&
							utilities.objectValidate(_this.location, ['end', 'coords', 0], false)
						) {
							payload.waypoints = [{ location: { lat: _this.location.start.coords[0], lng: _this.location.start.coords[1] } }]

							if (utilities.objectValidate(_this.location, ['return', 'coords', 0], false)) {
								payload.destination = { lat: _this.location.return.coords[0], lng: _this.location.return.coords[1] }
								payload.waypoints.push({ location: { lat: _this.location.end.coords[0], lng: _this.location.end.coords[1] } })
							} else {
								payload.destination = { lat: _this.location.end.coords[0], lng: _this.location.end.coords[1] }
							}
						}

						makeRoute(payload)
					} else {
						if (utilities.objectValidate(_this.location, ['initial', 'coords', 0], false) && utilities.objectValidate(_this.location, ['end', 'coords', 0], false)) {
							payload.destination = { lat: _this.location.return.coords[0], lng: _this.location.return.coords[1] }
							payload.waypoints = [{ location: { lat: _this.location.end.coords[0], lng: _this.location.end.coords[1] } }]
						}
						makeRoute(payload)
					}
				}
			}
		})
	},
	methods: {
		numeral,
		clearData() {
			this.waypoints = []
			this.nearPlace = {}
			this.routes = []
			this.location = {
				initial: {
					name: '',
					coords: [mapOptions.center.lat, mapOptions.center.lng],
				},
				start: {
					name: '',
					coords: [],
				},
				end: {
					name: '',
					coords: [],
				},
				return: {
					name: '',
					coords: [],
				},
			}
		},
		copyQuotation(type) {
			let message = `¡COMPA! 🤜🤛 \n
Muchas gracias por tu tiempo de espera, ya tenemos tu cotización:\n\n`

			if (type == 1) {
				message += `Costo del Servicio: ${numeral(this.getTotalUniqueCost * 1.15).format('$0,0.00')}
Descuento "LOS COMPAS" del 10%: -${numeral(this.getTotalUniqueCost * 1.15 * 0.1).format('$0,0.00')}
Total a Pagar: ${numeral(this.getTotalUniqueCost * 1.15 * 0.9).format('$0,0.00')}`
			}

			if (type == 2) {
				message += `Costo del Servicio: ${numeral(this.getTotalUniqueCost * 1.15).format('$0,0.00')}
Descuento "LOS COMPAS" del 10%: -${numeral(this.getTotalUniqueCost * 1.15 * 0.1).format('$0,0.00')}
Descuento "Chingón" del 10%: -${numeral(this.getTotalUniqueCost * 1.15 * 0.1 * 0.9).format('$0,0.00')}
Total a Pagar: ${numeral(this.getTotalUniqueCost * 1.15 * 0.9 * 0.9).format('$0,0.00')}`
			}

			message += `\n\nTiempo aproximado de traslado: ${this.routes[0].legs[1].duration.text}
			\n¿Te gustaría coordinar el traslado de tu moto?`
			copy(message)
		},
	},
	watch: {
		moveType() {
			this.clearData()
		},
	},
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>